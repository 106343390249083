const PassiveIncomeIcon = (props: SVGProps): JSX.Element => {
  const { title = 'PassiveIncomeIcon', ...restProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 144 108"
      {...restProps}
      fill="none"
    >
      <title>{title}</title>
      <path
        fill="#fff"
        d="M71.113 48.92a9.81 9.81 0 01-1.988-.201c-5.045-1.065-8.136-6.232-8.266-6.45l-.193-.332.31-.222c.206-.147 5.12-3.624 10.166-2.563 5.046 1.066 8.136 6.233 8.266 6.45l.193.332-.31.222c-.176.13-3.9 2.764-8.178 2.764zm-9.315-6.744c.768 1.145 3.531 4.886 7.503 5.72 3.968.84 8.01-1.463 9.176-2.201-.767-1.145-3.53-4.886-7.503-5.72-3.971-.843-8.014 1.463-9.176 2.201zM87.28 58.201c-4.277 0-8.002-2.633-8.182-2.764l-.31-.222.193-.331c.13-.218 3.22-5.39 8.266-6.45 5.045-1.061 9.96 2.411 10.166 2.562l.31.222-.192.332c-.13.218-3.222 5.389-8.267 6.45a9.424 9.424 0 01-1.983.201zm-7.36-3.225c1.162.738 5.205 3.04 9.177 2.202 3.967-.839 6.735-4.576 7.503-5.72-1.162-.739-5.205-3.041-9.177-2.202-3.972.834-6.735 4.575-7.503 5.72zM115.241 77.095l-.591-.596 17.782-17.568a11.067 11.067 0 00-1.723-1.191c-2.336-1.317-5.952-2.29-9.927.537l-11.03 10.413-.575-.608L120.27 57.61c4.571-3.267 8.761-1.778 10.85-.604 1.371.772 2.185 1.577 2.218 1.61l.298.298-18.395 18.181z"
      />
      <path
        fill="#fff"
        d="M111.11 62.911l-.633-.55c.05-.058 5.016-5.745 11.684-10.534 6.891-4.95 13.014-.034 13.077.016l.314.26-4.307 5.524-.662-.516 3.791-4.865c-1.296-.902-6.169-3.733-11.726.26-6.589 4.735-11.492 10.35-11.538 10.405zM79.648 28.4h-.84V59.58h.84V28.4z"
      />
      <path
        fill="inherit"
        d="M93.99 16.862c0-1.443 2.42-3.18 2.064-4.509-.369-1.375-3.343-1.669-4.039-2.872-.704-1.22.516-3.943-.474-4.932-.99-.99-3.711.23-4.932-.474-1.203-.697-1.497-3.67-2.872-4.04C82.407-.32 80.67 2.1 79.228 2.1 77.79 2.1 76.05-.316 74.72.036c-1.376.369-1.67 3.342-2.873 4.039-1.22.704-3.942-.516-4.932.474-.99.99.23 3.711-.474 4.932-.696 1.203-3.67 1.497-4.039 2.872-.356 1.33 2.063 3.066 2.063 4.509 0 1.438-2.415 3.179-2.063 4.508.37 1.376 3.343 1.67 4.039 2.873.704 1.22-.516 3.942.474 4.932.99.99 3.711-.23 4.932.474 1.203.696 1.497 3.67 2.873 4.039 1.33.356 3.065-2.064 4.508-2.064s3.18 2.42 4.509 2.064c1.375-.37 1.669-3.343 2.872-4.039 1.22-.704 3.943.516 4.932-.474.99-.99-.23-3.711.474-4.932.697-1.203 3.67-1.497 4.04-2.873.356-1.33-2.064-3.07-2.064-4.508z"
      />
      <path
        fill="#fff"
        d="M79.228 29.12c-6.76 0-12.259-5.498-12.259-12.258 0-6.76 5.499-12.259 12.26-12.259 6.76 0 12.258 5.498 12.258 12.259 0 6.756-5.498 12.259-12.259 12.259zm0-23.682c-6.295 0-11.42 5.125-11.42 11.42s5.12 11.42 11.42 11.42 11.42-5.121 11.42-11.42c0-6.295-5.125-11.42-11.42-11.42z"
      />
      <path
        fill="#fff"
        d="M79.228 26.667c-5.41 0-9.81-4.4-9.81-9.805 0-5.406 4.4-9.805 9.81-9.805 5.406 0 9.805 4.399 9.805 9.805s-4.395 9.805-9.805 9.805zm0-18.776c-4.945 0-8.97 4.022-8.97 8.967 0 4.944 4.025 8.966 8.97 8.966s8.967-4.022 8.967-8.966c.004-4.945-4.022-8.967-8.967-8.967zM46.692 51.97a7.33 7.33 0 01-.738-.038c-3.754-.412-6.375-3.943-6.484-4.094l-.227-.31.286-.252c.138-.121 3.46-3.011 7.213-2.596 3.754.411 6.375 3.943 6.484 4.093l.226.31-.289.252c-.13.114-3.032 2.634-6.471 2.634zm-6.295-4.316c.713.847 2.88 3.14 5.645 3.443 2.768.302 5.376-1.468 6.257-2.139-.713-.847-2.881-3.141-5.645-3.443-2.764-.306-5.38 1.463-6.257 2.139zM59.387 58.122c-2.798 0-5.226-1.38-5.352-1.456l-.331-.193.16-.348c.079-.167 1.95-4.147 5.548-5.288 3.598-1.14 7.423 1.031 7.582 1.124l.332.193-.16.348c-.08.167-1.95 4.148-5.548 5.288a7.419 7.419 0 01-2.231.332zm-4.58-1.997c.994.487 3.904 1.707 6.555.868 2.65-.838 4.324-3.514 4.857-4.487-.994-.486-3.905-1.707-6.556-.868-2.646.839-4.32 3.514-4.856 4.487z"
      />
      <path
        fill="#fff"
        d="M52.98 42.026l-.835.088 1.868 17.722.834-.088-1.868-17.722z"
      />
      <path
        fill="#fff"
        d="M51.737 42.721c-4.55 0-8.46-3.46-8.92-8.081-.491-4.92 3.115-9.323 8.04-9.814 4.919-.49 9.322 3.116 9.813 8.04.49 4.92-3.116 9.323-8.04 9.814-.298.025-.595.041-.893.041zm.012-17.102c-.268 0-.536.012-.809.042-4.462.444-7.73 4.433-7.285 8.895.445 4.462 4.433 7.73 8.895 7.285 4.463-.445 7.73-4.433 7.285-8.895-.415-4.19-3.963-7.327-8.086-7.327z"
      />
      <path
        fill="#fff"
        d="M51.733 40.277A6.476 6.476 0 0147.61 38.8a6.467 6.467 0 01-2.357-4.403c-.356-3.582 2.269-6.782 5.85-7.138 3.582-.357 6.782 2.268 7.139 5.846a6.481 6.481 0 01-1.447 4.781 6.482 6.482 0 01-4.4 2.357 7.146 7.146 0 01-.662.034zm.017-12.213a5.69 5.69 0 00-5.662 6.249 5.665 5.665 0 002.055 3.837 5.639 5.639 0 004.169 1.258 5.665 5.665 0 003.837-2.055 5.634 5.634 0 001.258-4.164 5.698 5.698 0 00-5.657-5.125zM104.149 55.207c-1.997 0-3.611-.545-3.716-.583l-.361-.126.092-.373c.046-.18 1.133-4.441 4.454-6.24 3.318-1.8 7.482-.387 7.658-.328l.361.126-.092.373c-.046.18-1.133 4.442-4.454 6.24-1.284.697-2.688.91-3.942.91zm-3.062-1.263c1.069.29 4.156.94 6.601-.385 2.449-1.33 3.586-4.27 3.926-5.322-1.07-.29-4.156-.94-6.602.385-2.445 1.326-3.585 4.27-3.925 5.322z"
      />
      <path
        fill="#fff"
        d="M101.42 41.963l-2.215 17.725.832.104 2.215-17.725-.832-.104z"
      />
      <path
        fill="#fff"
        d="M102.681 42.722c-.298 0-.596-.013-.894-.047-4.92-.49-8.526-4.89-8.04-9.813.487-4.924 4.89-8.53 9.814-8.04 4.92.49 8.527 4.89 8.04 9.814-.461 4.621-4.37 8.086-8.92 8.086zm-.009-17.103c-4.127 0-7.666 3.137-8.086 7.327-.444 4.462 2.827 8.45 7.285 8.895 4.463.444 8.451-2.827 8.896-7.285.444-4.462-2.827-8.45-7.285-8.895a7.594 7.594 0 00-.81-.042z"
      />
      <path
        fill="#fff"
        d="M102.689 40.276c-.218 0-.436-.012-.659-.033a6.482 6.482 0 01-4.399-2.357 6.488 6.488 0 01-1.447-4.781 6.53 6.53 0 0111.542-3.49 6.488 6.488 0 011.447 4.782 6.521 6.521 0 01-6.484 5.88zm-.017-12.212a5.69 5.69 0 00-5.653 5.125 5.652 5.652 0 001.258 4.164 5.655 5.655 0 003.837 2.055 5.667 5.667 0 004.169-1.258 5.648 5.648 0 002.055-3.837 5.654 5.654 0 00-1.258-4.165 5.667 5.667 0 00-3.838-2.055 5.363 5.363 0 00-.57-.03zM108.338 72.092l3.078-11.093a1.003 1.003 0 00-.968-1.271H46.746c-.596 0-1.057.512-.998 1.103l1.3 12.812c.055.525.503.919 1.028.902l59.323-1.72c.44-.012.822-.31.939-.733z"
      />
      <path
        fill="#fff"
        d="M48.046 74.964a1.423 1.423 0 01-1.413-1.279l-1.3-12.812a1.428 1.428 0 011.417-1.569h63.702c.449 0 .86.206 1.132.562.273.357.357.81.239 1.242L108.745 72.2a1.412 1.412 0 01-1.33 1.04l-59.323 1.72c-.016.003-.029.003-.046.003zm-1.3-14.82a.563.563 0 00-.432.192.579.579 0 00-.147.453l1.3 12.812c.03.302.294.533.6.525l59.323-1.72a.582.582 0 00.545-.428l3.079-11.093a.578.578 0 00-.097-.507.588.588 0 00-.465-.23H46.746v-.005zM79.228 22.297l-8.488-5.435 8.488-5.435 8.489 5.435-8.489 5.435zm-6.936-5.44l6.932 4.442 6.933-4.441-6.933-4.442-6.933 4.442z"
      />
      <path
        fill="#222325"
        d="M87.268 82.522h28.913c2.571-10.162-8.761-9.617-20.24-12.527-6.11-1.552-10.753-3.38-25.055-2.89-34.792 1.191-33.731 27.948-33.731 27.948v12.528H69.18V91.778"
      />
      <path
        fill="#fff"
        d="M69.599 108H36.735V95.053c-.004-.084-.31-10.078 7.16-18.122 5.934-6.387 15.01-9.834 26.975-10.245 12.636-.432 17.673.91 23.007 2.336.714.188 1.43.381 2.165.566 2.327.591 4.63 1.036 6.857 1.464 5.947 1.144 11.084 2.134 13.131 5.355 1.023 1.61 1.204 3.645.553 6.22l-.079.314H87.268v-.838h28.582c.499-2.19.327-3.91-.525-5.247-1.853-2.915-6.823-3.871-12.582-4.982-2.239-.432-4.554-.877-6.907-1.472-.742-.19-1.46-.382-2.177-.57-5.271-1.41-10.25-2.735-22.764-2.308-11.73.403-20.605 3.754-26.38 9.97-7.234 7.78-6.95 17.446-6.945 17.543v12.124h31.186V91.778h.839V108h.004z"
      />
      <path
        fill="#fff"
        d="M92.129 92.118c-.483 0-.764-.021-.785-.021H65.581v-.84h25.797c.15.013 11.823.819 17.304-8.94l.73.41c-4.895 8.728-14.482 9.39-17.283 9.39zM37.18 95.594H8.137c-4.412 0-7.998-3.59-7.998-7.997 0-4.408 3.59-7.998 7.998-7.998H19.98c3.946 0 7.159-3.213 7.159-7.16v-4.5h23.192v.84H27.978v3.66c0 4.413-3.59 7.999-7.997 7.999H8.137c-3.947 0-7.16 3.212-7.16 7.159 0 3.946 3.213 7.159 7.16 7.159H37.18v.838z"
      />
      <path
        fill="#fff"
        d="M135.863 108H61.24v-.839h74.623c3.946 0 7.159-3.212 7.159-7.159 0-3.946-3.213-7.159-7.159-7.159H116.89c-4.412 0-7.998-3.59-7.998-7.998v-2.294h.839v2.29c0 3.947 3.212 7.16 7.159 7.16h18.973c4.412 0 7.998 3.59 7.998 7.997 0 4.412-3.59 8.002-7.998 8.002z"
      />
    </svg>
  );
};

export { PassiveIncomeIcon };
