import cn from 'classnames';

const QRCodeIcon = (props: SVGProps): JSX.Element => {
  const { title = 'QRCode', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <g id="Icon">
        <g id="Icon_2">
          <path d="M5.00537 3.66667H3.66537V5H5.00537V3.66667Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.0441 1.33333C2.87585 1.33331 2.71198 1.33329 2.57321 1.34463C2.42082 1.35708 2.241 1.38646 2.06005 1.47866C1.80916 1.60649 1.60519 1.81047 1.47736 2.06135C1.38516 2.2423 1.35578 2.42212 1.34333 2.57452C1.33199 2.71329 1.33201 2.87714 1.33203 3.04539L1.33203 5.62127C1.33201 5.78952 1.33199 5.95339 1.34333 6.09216C1.35578 6.24455 1.38516 6.42437 1.47736 6.60532C1.60519 6.85621 1.80916 7.06018 2.06005 7.18801C2.241 7.28021 2.42082 7.30959 2.57321 7.32204C2.71199 7.33338 2.87586 7.33336 3.04411 7.33334H5.61996C5.78821 7.33336 5.95208 7.33338 6.09085 7.32204C6.24325 7.30959 6.42307 7.28021 6.60402 7.18801C6.85491 7.06018 7.05888 6.85621 7.18671 6.60532C7.27891 6.42437 7.30829 6.24455 7.32074 6.09216C7.33208 5.95338 7.33206 5.78952 7.33204 5.62126V3.04542C7.33206 2.87716 7.33208 2.71329 7.32074 2.57452C7.30829 2.42212 7.27891 2.2423 7.18671 2.06135C7.05888 1.81047 6.85491 1.60649 6.60402 1.47866C6.42307 1.38646 6.24325 1.35708 6.09085 1.34463C5.95209 1.33329 5.78823 1.33331 5.61998 1.33333L3.0441 1.33333ZM2.67224 2.68309L2.673 2.6743L2.68179 2.67354C2.75951 2.66719 2.86768 2.66667 3.06537 2.66667H5.5987C5.79639 2.66667 5.90456 2.66719 5.98228 2.67354L5.99107 2.6743L5.99183 2.68309C5.99818 2.76082 5.9987 2.86898 5.9987 3.06667V5.6C5.9987 5.79769 5.99818 5.90586 5.99183 5.98358L5.99107 5.99237L5.98228 5.99313C5.90456 5.99948 5.79639 6 5.5987 6H3.06537C2.86768 6 2.75951 5.99948 2.68179 5.99313L2.673 5.99237L2.67224 5.98358C2.66589 5.90586 2.66537 5.79769 2.66537 5.6V3.06667C2.66537 2.86898 2.66589 2.76081 2.67224 2.68309Z"
          />
          <path d="M12.3387 3.66667H10.9987V5H12.3387V3.66667Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.9533 1.33333H10.3774C10.2092 1.33331 10.0453 1.33329 9.90655 1.34463C9.75415 1.35708 9.57433 1.38646 9.39338 1.47866C9.1425 1.60649 8.93852 1.81047 8.81069 2.06135C8.71849 2.2423 8.68912 2.42212 8.67666 2.57452C8.66533 2.71329 8.66535 2.87716 8.66537 3.04541V5.62126C8.66535 5.78952 8.66533 5.95338 8.67666 6.09216C8.68912 6.24455 8.71849 6.42437 8.81069 6.60532C8.93852 6.8562 9.1425 7.06018 9.39338 7.18801C9.57433 7.28021 9.75415 7.30959 9.90655 7.32204C10.0453 7.33338 10.2092 7.33336 10.3774 7.33334H12.9533C13.1215 7.33336 13.2854 7.33338 13.4242 7.32204C13.5766 7.30959 13.7564 7.28021 13.9374 7.18801C14.1882 7.06018 14.3922 6.8562 14.52 6.60532C14.6122 6.42437 14.6416 6.24455 14.6541 6.09216C14.6654 5.95341 14.6654 5.78958 14.6654 5.62136V3.04539C14.6654 2.87717 14.6654 2.71326 14.6541 2.57452C14.6416 2.42212 14.6122 2.2423 14.52 2.06135C14.3922 1.81047 14.1882 1.60649 13.9374 1.47866C13.7564 1.38646 13.5766 1.35708 13.4242 1.34463C13.2854 1.33329 13.1216 1.33331 12.9533 1.33333ZM10.0056 2.68309L10.0063 2.6743L10.0151 2.67354C10.0928 2.66719 10.201 2.66667 10.3987 2.66667H12.932C13.1297 2.66667 13.2379 2.66719 13.3156 2.67354L13.3244 2.6743L13.3252 2.68309C13.3315 2.76082 13.332 2.86898 13.332 3.06667V5.6C13.332 5.79769 13.3315 5.90586 13.3252 5.98358L13.3244 5.99236L13.3156 5.99313C13.2379 5.99948 13.1297 6 12.932 6H10.3987C10.201 6 10.0928 5.99948 10.0151 5.99313L10.0063 5.99237L10.0056 5.98358C9.99922 5.90586 9.9987 5.79769 9.9987 5.6V3.06667C9.9987 2.86898 9.99922 2.76082 10.0056 2.68309Z"
          />
          <path d="M5.00537 11H3.66537V12.3333H5.00537V11Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.61996 8.66667H3.04411C2.87585 8.66665 2.71199 8.66663 2.57321 8.67797C2.42082 8.69042 2.241 8.7198 2.06005 8.81199C1.80917 8.93982 1.60519 9.1438 1.47736 9.39468C1.38516 9.57563 1.35578 9.75545 1.34333 9.90785C1.33199 10.0466 1.33201 10.2105 1.33203 10.3787L1.33203 12.9546C1.33201 13.1229 1.33199 13.2867 1.34333 13.4255C1.35578 13.5779 1.38516 13.7577 1.47736 13.9387C1.60519 14.1895 1.80917 14.3935 2.06005 14.5213C2.241 14.6135 2.42082 14.6429 2.57321 14.6554C2.71196 14.6667 2.87579 14.6667 3.04401 14.6667H5.61998C5.7882 14.6667 5.95211 14.6667 6.09085 14.6554C6.24325 14.6429 6.42307 14.6135 6.60402 14.5213C6.8549 14.3935 7.05888 14.1895 7.18671 13.9387C7.27891 13.7577 7.30829 13.5779 7.32074 13.4255C7.33208 13.2867 7.33206 13.1228 7.33204 12.9546V10.3787C7.33206 10.2105 7.33208 10.0466 7.32074 9.90785C7.30829 9.75545 7.27891 9.57563 7.18671 9.39468C7.05888 9.1438 6.8549 8.93982 6.60402 8.81199C6.42307 8.7198 6.24325 8.69042 6.09085 8.67797C5.95208 8.66663 5.78821 8.66665 5.61996 8.66667ZM2.67224 10.0164L2.673 10.0076L2.68179 10.0069C2.75951 10.0005 2.86768 10 3.06537 10H5.5987C5.79639 10 5.90456 10.0005 5.98228 10.0069L5.99107 10.0076L5.99183 10.0164C5.99818 10.0941 5.9987 10.2023 5.9987 10.4V12.9333C5.9987 13.131 5.99818 13.2392 5.99183 13.3169L5.99107 13.3257L5.98228 13.3265C5.90456 13.3328 5.79639 13.3333 5.5987 13.3333H3.06537C2.86768 13.3333 2.75951 13.3328 2.68179 13.3265L2.673 13.3257L2.67224 13.3169C2.66589 13.2392 2.66537 13.131 2.66537 12.9333V10.4C2.66537 10.2023 2.66589 10.0941 2.67224 10.0164Z"
          />
          <path d="M9.66537 11.3333V10H8.66536V8.66667H10.9987V11.3333H9.66537Z" />
          <path d="M13.6654 8.66667H12.332V11.3333H14.6654V10H13.6654V8.66667Z" />
          <path d="M14.6654 12.6667H13.332L13.3321 14.6667H14.6654L14.6654 12.6667Z" />
          <path d="M11.9987 12.6667H8.66539V14.6667H9.9987V13.7778H11.9987V12.6667Z" />
        </g>
      </g>
    </svg>
  );
};

export { QRCodeIcon };
