import cn from 'classnames';

const FileIcon = (props: SVGProps): JSX.Element => {
  const { title = 'File', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <g id="Files &#38; Office  / File">
        <g id="Icon">
          <path d="M5 11H11V10L5 10L5 11Z" />
          <path d="M5 12L5 13H9L9 12H5Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 1.29289C7.18754 1.10536 7.44189 1 7.70711 1H11.5C12.3284 1 13 1.67157 13 2.5V13.5C13 14.3284 12.3284 15 11.5 15H4.5C3.67157 15 3 14.3284 3 13.5V5.70711C3 5.44189 3.10536 5.18754 3.29289 5L7 1.29289ZM8 2V4.5C8 5.32843 7.32843 6 6.5 6H4V13.5C4 13.7761 4.22386 14 4.5 14H11.5C11.7761 14 12 13.7761 12 13.5V2.5C12 2.22386 11.7761 2 11.5 2L8 2ZM4.70711 5H6.5C6.77614 5 7 4.77614 7 4.5V2.70711L4.70711 5Z"
          />
        </g>
      </g>
    </svg>
  );
};

export { FileIcon };
