const SettlementIllustration = (props: SVGProps): JSX.Element => {
  const { title = 'SettlementIllustration', ...restProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 205 108"
      {...restProps}
      fill="none"
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_125_5424)">
        <path
          stroke="inherit"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="0.645"
          fill="none"
          d="M44.057 16.482s-15.077 13.4-22.475 13.4c-7.399 0-13.4-5.999-13.4-13.4 0-7.401 5.998-13.4 13.397-13.4 7.398 0 22.475 13.4 22.475 13.4h.003z"
        />
        <path
          stroke="inherit"
          fill="none"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="0.645"
          d="M43.973 16.482s15.077-13.4 22.475-13.4c7.401 0 13.4 5.999 13.4 13.4 0 7.401-5.999 13.4-13.4 13.4-7.401 0-22.475-13.4-22.475-13.4z"
        />
        <path
          stroke="#fff"
          fill="none"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="0.645"
          d="M190.001 5.528h-52.75v52.75h52.75V5.528zM63.055 38.578H1.281V95.99h61.774V38.578z"
        />
        <path
          stroke="inherit"
          fill="none"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="0.645"
          d="M9.566 45.701h46.347M9.566 49.188h9.45M9.566 65.667l6.831-6.177 4.506 3.089 9.594-7.012 5.088 3.341h8.285l3.342 2.618 7.995-5.96M44.015 83.636h11.192M50.264 86.544h4.943M200.033 14.338h-27.479M201.474 12.36l-3.601 3.96M197.873 12.36l3.601 3.96"
        />
        <path
          fill="#fff"
          stroke="none"
          d="M16.454 87.774c-1.818-.715-3.477-1.728-4.671-2.859-1.451-1.37-2.217-2.901-2.217-4.428v-7.253l7.109-3.1 7.07 3.09v7.263c0 1.527-.767 3.056-2.218 4.428-1.194 1.131-2.856 2.144-4.671 2.86l-.203.078-.202-.079h.003zM54.125 20.69h88.245c1.023 0 1.852.829 1.852 1.851v69.462H52.276V22.538c0-1.022.83-1.852 1.852-1.852l-.003.003z"
        />
        <path
          stroke="#000"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="0.645"
          d="M59.949 83.23h-5.453M64.358 79.749h-9.862M64.358 76.265h-9.862M64.358 72.785h-9.862M60.594 69.301h-6.098M89.913 46.32H76.43M122.039 51.637h-6.095M82.526 51.637h-6.095M82.526 60.253h-6.095M70.632 20.759v71.244M52.276 38.637h18.482M52.276 44.96h18.482M52.276 51.284h18.482"
        />
        <path
          fill="#000"
          stroke="#000"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="0.645"
          d="M138.672 26.6c.5 0 .905-.406.905-.907a.906.906 0 00-.905-.908.906.906 0 00-.904.908c0 .501.405.908.904.908zM135.535 26.6c.5 0 .905-.406.905-.907a.906.906 0 00-.905-.908c-.5 0-.905.407-.905.908 0 .501.405.908.905.908zM132.396 26.6c.5 0 .905-.406.905-.907a.906.906 0 00-.905-.908.907.907 0 000 1.816z"
        />
        <path
          stroke="#000"
          fill="none"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="0.645"
          d="M140.971 31.972c.497 0 .901.347.901.776v3.77c0 .428-.404.775-.901.775H74.564c-.497 0-.901-.347-.901-.776v-3.77c0-.428.404-.775.901-.775h66.407z"
        />
        <path
          fill="inherit"
          d="M141.24 41.657c.349 0 .636.265.636.59v24.476c0 .326-.284.59-.636.59H74.498c-.35 0-.636-.264-.636-.59V42.248c0-.326.284-.591.636-.591h66.742z"
        />
        <path
          stroke="#000"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="0.645"
          d="M110.21 51.637H84.653a.32.32 0 00-.32.32v1.55c0 .176.143.32.32.32h25.557a.32.32 0 00.32-.32v-1.55a.32.32 0 00-.32-.32zM130.164 53.826h8.216a.443.443 0 00.443-.443V52.08a.443.443 0 00-.443-.444h-8.216a.443.443 0 00-.443.444v1.303c0 .245.198.443.443.443zM130.164 46.32h8.216a.443.443 0 00.443-.444v-1.303a.443.443 0 00-.443-.443h-8.216a.443.443 0 00-.443.443v1.303c0 .245.198.443.443.443zM116.387 46.32h8.216a.444.444 0 00.443-.444v-1.303a.444.444 0 00-.443-.443h-8.216a.443.443 0 00-.443.443v1.303c0 .245.198.443.443.443zM110.21 60.253H84.653a.32.32 0 00-.32.32v1.55c0 .177.143.32.32.32h25.557a.32.32 0 00.32-.32v-1.55a.32.32 0 00-.32-.32zM130.164 62.443h8.216a.443.443 0 00.443-.444v-1.303a.443.443 0 00-.443-.443h-8.216a.443.443 0 00-.443.444v1.302c0 .245.198.444.443.444zM76.431 56.733h62.395"
        />
        <path
          fill="#000"
          d="M67.44 29.306l-6.176-3.954-6.177 3.954 6.177 3.957 6.176-3.957z"
        />
        <path
          fill="inherit"
          stroke="inherit"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="0.645"
          d="M203.718 51.389h-30.241v33.96h30.241V51.39z"
        />
        <path
          stroke="#000"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="0.645"
          d="M177.917 75.692h21.721M177.917 78.331h10.68"
        />
        <path
          fill="#000"
          stroke="#000"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="0.645"
          d="M181.225 62.023h-1.873v3.833h1.873v-3.833zM184.774 60.198h-1.873v4.81h1.873v-4.81zM188.325 58.407h-1.873v5.079h1.873v-5.08zM191.874 58.407h-1.873v6.94h1.873v-6.94zM195.424 59.49h-1.873v3.996h1.873v-3.997zM198.974 62.228h-1.873v4.473h1.873v-4.473z"
        />
        <path
          fill="#fff"
          stroke="#fff"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="0.645"
          d="M181.225 65.857h-1.873v3.049h1.873v-3.05zM184.776 65.01h-1.873v3.893h1.873v-3.894zM188.325 63.483h-1.873v5.42h1.873v-5.42zM191.875 65.347h-1.873v3.556h1.873v-3.556zM195.425 63.483h-1.873v5.42h1.873v-5.42zM198.975 66.701h-1.873v2.202h1.873V66.7z"
        />
        <path
          stroke="#000"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="0.645"
          d="M176.982 68.903h23.232"
        />
        <path
          stroke="#fff"
          fill="none"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="0.645"
          d="M161.175 24.502h-7.106M174.741 34.642l-2.578 1.007M166.191 27.268l-6.457 3.718c-1.916 1.195-3.677-1.24-3.677-1.24l5.037-5.22c4.286-2.672 8.722-.057 8.722-.057l1.713.989 1.273-.473M163.138 35.462l5.119 5.272s-2.459 1.498-4.081.274l-3.577-3.686"
        />
        <path
          stroke="#fff"
          strokeLinecap="square"
          strokeMiterlimit="10"
          fill="none"
          strokeWidth="0.645"
          d="M165.017 32.88l6.973 7.187s-2.458 1.5-4.08.275l-5.263-5.372M165.018 27.988l10.592 11.06s-2.458 1.499-4.081.274l-6.511-6.445M152.163 33.972l7.537 7.211s2.166 1.496 3.981-.666M176.058 22.134l-3.131.63a.561.561 0 00-.44.66l2.386 11.866c.061.304.357.5.661.44l3.131-.63a.561.561 0 00.44-.66l-2.387-11.866a.56.56 0 00-.66-.44zM153.877 22.76l-2.705-.544a.529.529 0 00-.622.414l-2.399 11.93a.528.528 0 00.414.622l2.705.544a.529.529 0 00.622-.413l2.399-11.931a.528.528 0 00-.414-.622zM101.344 102.324H73.862"
        />
        <path
          fill="inherit"
          stroke="none"
          d="M91.665 104.543a2.22 2.22 0 100-4.44 2.22 2.22 0 000 4.44zM60.824 4.556a4.557 4.557 0 119.114 0 4.557 4.557 0 01-9.114 0z"
        />
        <path
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.51"
          d="M65.377 8.185a3.628 3.628 0 10.001-7.256 3.628 3.628 0 000 7.256z"
        />
        <path
          fill="#000"
          stroke="none"
          d="M66.873 3.595l-1.496-.682-1.493.682v1.924l1.493.678 1.496-.678V3.595z"
        />
        <path
          fill="inherit"
          stroke="none"
          d="M5.06 23.006a4.557 4.557 0 119.114 0 4.557 4.557 0 01-9.114 0z"
        />
        <path
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.51"
          d="M9.614 26.634a3.628 3.628 0 100-7.256 3.628 3.628 0 000 7.256z"
        />
        <path
          fill="#000"
          stroke="none"
          d="M11.254 21.365a1.64 1.64 0 100 3.281v-3.281z"
        />
        <path
          fill="#000"
          stroke="none"
          d="M7.973 24.646a1.64 1.64 0 100-3.281v3.281z"
        />
        <path
          fill="inherit"
          stroke="none"
          d="M38.64 8.882a4.557 4.557 0 10-9.114 0 4.557 4.557 0 009.114 0z"
        />
        <path
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.51"
          d="M34.086 12.51a3.628 3.628 0 11-.001-7.256 3.628 3.628 0 010 7.256z"
        />
        <path
          fill="#000"
          stroke="none"
          d="M35.425 10.637L34.13 6.755l-1.388 3.882 1.34-.968 1.342.968z"
        />
        <path
          stroke="inherit"
          fill="none"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="0.645"
          d="M151.626 95.789c3.793-7.631 3.607-15.438-.416-17.438-4.023-2-10.359 2.566-14.152 10.197-3.793 7.63-3.607 15.438.416 17.438 4.023 1.999 10.359-2.566 14.152-10.197zM159.814 91.074L136.238 78.97M158.143 99.307l-27.584-14.274M128.834 92.865l23.658 12.493M151.294 78.464l-13.94 27.467"
        />
        <path
          stroke="inherit"
          strokeLinecap="square"
          strokeMiterlimit="10"
          fill="none"
          strokeWidth="0.645"
          d="M144.348 107.677c8.56 0 15.499-6.939 15.499-15.5 0-8.56-6.939-15.499-15.499-15.499s-15.499 6.94-15.499 15.5c0 8.56 6.939 15.499 15.499 15.499z"
        />
      </g>
      <defs>
        <clipPath id="clip0_125_5424">
          <path
            fill="#fff"
            d="M0 0H203.082V108H0z"
            transform="translate(.959)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { SettlementIllustration };
