import { RumInitConfiguration, datadogRum } from '@datadog/browser-rum';

export enum DataDogEnvironments {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
}

const initDataDogRUM = (environmentConfig: RumInitConfiguration) => {
  datadogRum.init({
    ...environmentConfig,
    allowedTracingUrls: [
      {
        match: /https:\/\/*.anchorlabs.dev/,
        propagatorTypes: ['tracecontext'],
      },
      {
        match: /https:\/\/clientdashboard.anchorage-*.com/,
        propagatorTypes: ['tracecontext'],
      },
    ],
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
};

export default initDataDogRUM;
