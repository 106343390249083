const SignUpImage = (props: SVGProps): JSX.Element => {
  const { title = 'SignUpImage', ...restProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 178 110"
      {...restProps}
      fill="none"
    >
      <title>{title}</title>
      <path
        stroke="#fff"
        fill="none"
        strokeMiterlimit="10"
        strokeWidth="0.8"
        d="M113.447 109l55.733-.044c4.307 0 7.8-3.033 7.8-7.274 0-4.24-3.493-7.68-7.8-7.68h-21.263M95.065 31.033C94.155 14.293 80.295 1 63.327 1c-16.97 0-30.829 13.292-31.738 30.033h-.023v.523a31.626 31.626 0 000 2.466v53.717h63.55V31.033H95.064z"
      />
      <path
        fill="inherit"
        d="M69.669 18.114h16.173v69.267h-18.17v-67.27c0-1.103.894-1.997 1.997-1.997z"
      />
      <path
        fill="#222325"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.8"
        d="M85.579 18.413h69.602c.992 0 1.797.805 1.797 1.796v67.47h-71.4V18.412z"
      />
      <path
        fill="#fff"
        d="M151.654 24.187a.88.88 0 00.878-.881.88.88 0 00-.878-.881.88.88 0 00-.878.88.88.88 0 00.878.882zM148.605 24.187a.88.88 0 00.878-.881.88.88 0 00-.878-.881.88.88 0 00-.878.88.88.88 0 00.878.882zM145.559 24.187a.88.88 0 00.878-.881.88.88 0 00-.878-.881.88.88 0 00-.878.88.88.88 0 00.878.882z"
      />
      <path
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.8"
        d="M82.207 82.544h-7.952M75.123 79.162h-5.296M79.408 75.778h-9.581M75.145 32.434l-5.296.07M79.386 30.623l-9.581.124M79.408 72.396h-9.581M79.408 69.014h-9.581M75.75 65.632h-5.923M97.438 43.713h-5.92M77.548 83.92h-3.293"
      />
      <path
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.8"
        d="M103.41 31.73H89.79a.513.513 0 00-.513.513v5.699c0 .283.23.513.514.513h13.619c.283 0 .513-.23.513-.513v-5.699a.513.513 0 00-.513-.513zM119.615 31.73h-13.619a.514.514 0 00-.514.513v5.699c0 .283.23.513.514.513h13.619c.283 0 .513-.23.513-.513v-5.699a.513.513 0 00-.513-.513zM135.816 31.73h-13.618a.514.514 0 00-.514.513v5.699c0 .283.23.513.514.513h13.618c.284 0 .514-.23.514-.513v-5.699a.514.514 0 00-.514-.513z"
      />
      <path
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.8"
        d="M71.421 84.8a1.597 1.597 0 100-3.194 1.597 1.597 0 000 3.195zM124.331 49.675H91.829a.31.31 0 00-.31.31v1.506c0 .171.138.31.31.31h32.502a.31.31 0 00.31-.31v-1.506a.31.31 0 00-.31-.31z"
      />
      <path
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.8"
        d="M124.331 55.475H91.829a.31.31 0 00-.31.31v8.52c0 .171.138.31.31.31h32.502a.31.31 0 00.31-.31v-8.52a.31.31 0 00-.31-.31z"
      />
      <path
        stroke="#fff"
        fill="none"
        strokeMiterlimit="10"
        strokeWidth="0.8"
        d="M124.331 68.162H91.829a.31.31 0 00-.31.31v3.636c0 .171.138.31.31.31h32.502a.31.31 0 00.31-.31v-3.636a.31.31 0 00-.31-.31zM129.437 57.601h3.955a.31.31 0 00.311-.31v-1.506a.31.31 0 00-.311-.31h-3.955a.31.31 0 00-.311.31v1.506c0 .171.139.31.311.31zM129.437 51.798h3.955a.31.31 0 00.311-.31v-1.506a.31.31 0 00-.311-.31h-3.955a.31.31 0 00-.311.31v1.506c0 .171.139.31.311.31zM124.331 75.72H91.829a.31.31 0 00-.31.311v1.506c0 .171.138.31.31.31h32.502a.31.31 0 00.31-.31v-1.506a.31.31 0 00-.31-.31zM129.437 77.85h3.955a.31.31 0 00.311-.31v-1.505a.31.31 0 00-.311-.31h-3.955a.31.31 0 00-.311.31v1.505c0 .172.139.31.311.31zM129.437 70.288h3.955a.31.31 0 00.311-.31v-1.506a.31.31 0 00-.311-.31h-3.955a.31.31 0 00-.311.31v1.506c0 .171.139.31.311.31zM67.672 35.847h17.951M67.672 41.99h17.951M67.672 48.131h17.951M119.558 109H82.473v-7.575c-.057-2.52-2.133-4.56-4.69-4.56h-7.969c-2.56 0-4.633-2.042-4.633-4.561 0-2.52 2.076-4.561 4.633-4.561h87.167"
      />
      <path
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.8"
        d="M99.245 108.866c11.299 0 20.462-9.464 20.462-21.14H78.784c0 11.676 9.16 21.14 20.461 21.14z"
      />
      <path
        fill="inherit"
        d="M157.054 93.603c7.91 0 14.323-6.413 14.323-14.323s-6.413-14.322-14.323-14.322-14.322 6.412-14.322 14.322c0 7.91 6.412 14.323 14.322 14.323z"
      />
      <path
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.8"
        d="M134.875 108.999l10.288-20.841 10.668 20.841h-20.956z"
      />
      <path
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.8"
        d="M31.665 50.747L67.672 87.38"
      />
      <path
        fill="inherit"
        d="M110.496 10.673a4.456 4.456 0 100-8.912 4.456 4.456 0 000 8.912z"
      />
      <path
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.839"
        d="M119.779 3.675h32.873"
      />
      <path
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.8"
        d="M119.779 8.762h32.873M17.545 36.186H1.657a.637.637 0 00-.637.637v3.113c0 .352.285.637.637.637h15.888a.637.637 0 00.637-.637v-3.113a.637.637 0 00-.637-.637zM42.532 50.658h8.165a.637.637 0 00.636-.637v-3.113a.637.637 0 00-.636-.637h-8.165a.637.637 0 00-.637.637v3.113c0 .352.285.637.637.637z"
      />
      <path
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.8"
        d="M42.532 41.061h8.165a.637.637 0 00.636-.637v-3.112a.637.637 0 00-.636-.637h-8.165a.637.637 0 00-.637.637v3.112c0 .352.285.637.637.637zM36.834 46.272H1.83a.637.637 0 00-.637.636v3.113c0 .352.286.637.637.637h35.003a.637.637 0 00.637-.637v-3.113a.637.637 0 00-.637-.636z"
      />
    </svg>
  );
};

export { SignUpImage };
