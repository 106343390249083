import cn from 'classnames';

const JiraIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Jira', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <g id="mark-gradient-white-jira 1">
        <path
          id="Vector"
          d="M13.4856 2H7.7085C7.7085 3.44 8.87425 4.60571 10.3142 4.60571H11.377V5.6343C11.377 7.0743 12.5427 8.24 13.9827 8.24V2.49714C13.9827 2.22285 13.7599 2 13.4856 2Z"
          fill="white"
        />
        <path
          id="Vector_2"
          d="M10.6228 4.87988H4.8457C4.8457 6.31988 6.0114 7.48558 7.4514 7.48558H8.51425V8.51418C8.51425 9.95418 9.67995 11.1199 11.1199 11.1199V5.37703C11.1371 5.10273 10.9142 4.87988 10.6228 4.87988Z"
          fill="url(#paint0_linear_5014_3098)"
        />
        <path
          id="Vector_3"
          d="M7.7771 7.76001H2C2 9.20001 3.1657 10.3657 4.60569 10.3657H5.66855V11.3943C5.66855 12.8343 6.83425 14 8.27425 14V8.25716C8.27425 7.98286 8.0514 7.76001 7.7771 7.76001Z"
          fill="url(#paint1_linear_5014_3098)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5014_3098"
          x1="11.0077"
          y1="4.88616"
          x2="8.54955"
          y2="7.42108"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.176" stopColor="white" stopOpacity="0.4" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5014_3098"
          x1="8.311"
          y1="7.78186"
          x2="5.4689"
          y2="10.5472"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.176" stopColor="white" stopOpacity="0.4" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export { JiraIcon };
