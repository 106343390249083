/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Signal } from '@latitude/signal';
import { Spinner } from '@latitude/spinner';
import { Slot, Slottable } from '@radix-ui/react-slot';
import cn from 'classnames';
import { getSpinnerSize, getSpinnerVariant } from 'helpers';
import { ElementRef, forwardRef } from 'react';

import type { ButtonProps, ButtonSize, ButtonType, ButtonVariant } from 'types';

import { buttonVariants, iconsVariants, textAreaVariants } from './variants';

/**
 * A customizable button component.
 *
 * @component
 * @example
 * // Usage:
 * <Button variant="primary" size="medium">
 *   Click me
 * </Button>
 *
 * @param {Object} props - The component props.
 * @param {string} [props.className] - Additional CSS class name(s) for the button.
 * @param {string} [props.variant='primary'] - The button variant.
 * @param {string} [props.size='medium'] - The button size.
 * @param {string} [props.type='filled'] - The button type style.
 * @param {boolean} [props.disabled=false] - Whether the button is disabled.
 * @param {boolean} [props.loading=false] - Whether the button is in a loading state.
 * @param {boolean} [props.asChild=false] - Whether the button should be rendered as a child component.
 * @param {boolean} [props.selected=false] - Whether the button is selected.
 * @param {React.ReactNode} [props.leadingElement] - The leading icon element.
 * @param {React.ReactNode} [props.trailingElement] - The trailing icon element.
 * @param {React.ReactNode} [props.children] - The content of the button.
 * @param {React.HTMLProps<HTMLButtonElement>} [props...props] - Additional props to be spread to the underlying button element.
 * @param {React.Ref<ElementRef<'button'>>} ref - The ref to be forwarded to the underlying button element.
 * @returns {React.ReactElement} The rendered Button component.
 */
const Button = forwardRef<ElementRef<'button'>, ButtonProps>(
  (
    {
      className,
      variant = 'primary',
      size = 'medium',
      type = 'filled',
      disabled = false,
      loading = false,
      asChild = false,
      selected = false,
      leadingElement = undefined,
      trailingElement = undefined,
      children,
      onClick,
      signal,
      htmlType,
      rawProps = {},
      'data-testid': testID = 'btn-test',
      ...variantProps
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        ref={ref}
        className={cn(
          'relative',
          buttonVariants({
            variant,
            type,
            size,
            disabled,
            selected,
          }),
          className,
        )}
        data-loading={loading}
        data-testid={testID}
        tabIndex={loading || disabled ? -1 : 0}
        disabled={disabled}
        onClick={onClick}
        type={htmlType}
        {...variantProps}
        {...rawProps}
      >
        <Slottable>
          <div
            className={`flex w-full items-center ${
              !loading && (leadingElement || trailingElement)
                ? 'justify-between'
                : 'justify-center'
            }`}
          >
            {loading && (
              <Spinner
                size={getSpinnerSize(size)}
                className="absolute m-auto"
                variant={getSpinnerVariant(variant, type)}
              />
            )}
            {leadingElement && (
              <span className={cn(iconsVariants({ size, loading }))}>
                {leadingElement}
              </span>
            )}
            {children && (
              <div className={cn(textAreaVariants({ size, loading }))}>
                {children}
              </div>
            )}
            {trailingElement && (
              <span className={cn(iconsVariants({ size, loading }))}>
                {trailingElement}
              </span>
            )}
          </div>
        </Slottable>
        {signal && (
          <Signal
            {...signal}
            border
            className={cn([
              'absolute',
              '-right-sizing-sys-size-4',
              '-top-sizing-sys-size-4',
            ])}
          />
        )}
      </Comp>
    );
  },
);

Button.displayName = 'Button';

export {
  Button,
  buttonVariants,
  type ButtonProps,
  type ButtonSize,
  type ButtonType,
  type ButtonVariant,
};
