import cn from 'classnames';

const TriangleWarningIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Warning', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M11.25 14.25V9h1.5v5.25h-1.5zM11.25 17.25v-1.5h1.5v1.5h-1.5z" />
      <path
        fillRule="evenodd"
        d="M13.979 3.958c-.85-1.573-3.108-1.573-3.958 0L2.6 17.68C1.789 19.179 2.874 21 4.579 21H19.42c1.704 0 2.79-1.821 1.979-3.32L13.979 3.958zm-2.639.713a.75.75 0 011.32 0l7.42 13.722a.75.75 0 01-.66 1.107H4.58a.75.75 0 01-.66-1.107L11.34 4.671z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export { TriangleWarningIcon };
