import cn from 'classnames';

const OperationsIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Operations', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <g id="Files &#38; Office  / Operations">
        <g id="Icon">
          <path d="M5 3H14V4H5V3Z" />
          <path d="M5 6H14V7H5V6Z" />
          <path d="M4 9H2V10H4V9Z" />
          <path d="M14 9H5V10H14V9Z" />
          <path d="M5 12H14V13H5V12Z" />
          <path d="M4 12H2V13H4V12Z" />
          <path d="M4 6H2V7H4V6Z" />
          <path d="M4 3H2V4H4V3Z" />
        </g>
      </g>
    </svg>
  );
};

export { OperationsIcon };
