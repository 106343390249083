import * as ToastPrimitives from '@radix-ui/react-toast';
import cn from 'classnames';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

/**
 * Represents the viewport for displaying toast notifications.
 *
 * @component
 * @example
 * ```tsx
 * <ToastViewport>
 *   <ToastNotification message="Hello, world!" />
 * </ToastViewport>
 * ```
 */
export const ToastViewport = forwardRef<
  ElementRef<typeof ToastPrimitives.Viewport>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className={cn(
      'fixed bottom-0 left-0 z-[100] flex max-h-screen flex-col-reverse gap-4 p-4',
      className,
    )}
    {...props}
  />
));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;
