import cn from 'classnames';

const BarChartIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BarChart', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <g id="product_final_bar_chart_24px">
        <g id="Icon">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.5 14L2.5 9H3.5L3.5 14H2.5Z"
          />
          <path fillRule="evenodd" clipRule="evenodd" d="M5 14L5 5H6L6 14H5Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5 14L7.5 2L8.5 2L8.5 14H7.5Z"
          />
          <path fillRule="evenodd" clipRule="evenodd" d="M10 14V9H11V14H10Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5 14V6H13.5V14H12.5Z"
          />
        </g>
      </g>
    </svg>
  );
};

export { BarChartIcon };
