
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "Actor": [
      "APIKeyV2",
      "AnchorageActor",
      "UserKey",
      "UserV2"
    ],
    "Button": [
      "BooleanButton"
    ],
    "ErrorMessage": [
      "OutstandingHoldsError"
    ],
    "IAMMember": [
      "IAMMemberUser"
    ],
    "IAMResource": [
      "Organization",
      "Vault"
    ],
    "Initiator": [
      "APIKey",
      "User"
    ],
    "Key": [
      "APIKeyV2",
      "UserKey"
    ],
    "Node": [
      "APIKeyV2",
      "AccountConstraint",
      "AccountConstraintGroup",
      "AccountConstraintState",
      "AccountV2",
      "AddressBalance",
      "AffiliateAnchorageRelationship",
      "AffiliateV2",
      "AllocatedCollateral",
      "AnchorageLegalEntity",
      "AssetReferenceData",
      "AssetSpec",
      "BlockchainAddress",
      "BorrowerInterestSnapshot",
      "CCRValuationCollateralDetails",
      "CCRValuationExposureDetails",
      "CCRValuations",
      "CRRScoreDetails",
      "ClientOrder",
      "ClientPaymentFlow",
      "ClientSettlement",
      "CollateralAccount",
      "CollateralAgreement",
      "CollateralCall",
      "CollateralFee",
      "CollateralInventory",
      "CollateralRehypoLedger",
      "CollateralSettlement",
      "CryptoCollateralDetails",
      "DataSource",
      "DepositAttributionSpamThreshold",
      "EligibilityProof",
      "EligibilitySchedule",
      "EnabledModules",
      "EquityCollateralDetails",
      "Exposure",
      "ExternalAccount",
      "ExternalAccountLocation",
      "ExternalAccountTransaction",
      "FeeReimbursement",
      "IAMCategory",
      "IAMMemberUser",
      "IAMPermission",
      "IAMRole",
      "IAMUserAccess",
      "IAMUserRole",
      "IndividualAffiliateRelationshipAudit",
      "InterestPayments",
      "KYCProcess",
      "KYCProcessTrigger",
      "KYCQuestion",
      "KYCQuestionnaire",
      "KYCQuestionnaireSubmission",
      "KeyInfo",
      "LendingEntity",
      "LendingFacility",
      "LendingFacilityDailyApy",
      "LendingFacilityFee",
      "LendingFacilityFeeAmountSnapshot",
      "LendingFacilityMembership",
      "LendingFacilityMembershipStake",
      "LendingFacilityTransaction",
      "LendingInvoice",
      "LendingJobHistoryRecord",
      "LendingScheduledJobRecord",
      "Loan",
      "LoanAmountSnapshot",
      "MiningCollateralDetails",
      "MintBurnConversion",
      "OrganizationModule",
      "Party",
      "PaymentFlow",
      "Person",
      "PolicyFragmentBinding",
      "PolicyQuorum",
      "PriceReferenceData",
      "ReadPolicy",
      "ReadQuorum",
      "Report",
      "ReportDataFilter",
      "ReportDataFilterValue",
      "ReportFile",
      "ReportSchedule",
      "ReportScheduleDataFilter",
      "ReportScheduleDataFilterValue",
      "ReportScheduleFileFormats",
      "ReportScheduleMainFilter",
      "ReportScheduleSftpDestination",
      "SNParticipant",
      "SNTwoWaySettlement",
      "TransferRequest",
      "UniqueDevice",
      "User",
      "UserKey",
      "UserV2",
      "Vault",
      "Wallet",
      "WalletConnectSession",
      "WalletGroup",
      "WebhookEndpoint",
      "WebhookEvent",
      "WebhookEventType",
      "WebhookNotification",
      "WebhookSubscription"
    ],
    "Operation": [
      "AddOrganizationOperation",
      "AddTrustedDestinationOperation",
      "AddUserAndPoliciesOperation",
      "AuthorizeSpenderOperation",
      "BalanceAdjustmentOperation",
      "ChangeRoleOperation",
      "CreateVaultOperation",
      "DelegateOperation",
      "DepositEventOperation",
      "DepositOperation",
      "DisableVaultOperation",
      "GenericOperation",
      "GenericOrgChangeOperation",
      "LockOperation",
      "OffChainOperation",
      "OneOffOperation",
      "RemoveTrustedDestinationOperation",
      "RemoveUserAndPoliciesOperation",
      "ReplaceUserOperation",
      "TransferOperation",
      "UnlockOperation",
      "UpdateOrgPoliciesOperation",
      "UpdateVaultPoliciesOperation",
      "VoteOperation",
      "WithdrawOperation"
    ],
    "OperationField": [
      "SignedOperationField",
      "UnsignedOperationField"
    ],
    "PolicyFragment": [
      "PolicyStructuredFragment"
    ],
    "PolicyResource": [
      "Organization",
      "Vault"
    ],
    "PresentationRule": [
      "PresentationRuleJustOnce"
    ],
    "Resource": [
      "Organization",
      "Vault"
    ],
    "RolePermissionDestinationDetail": [
      "AMLBlockchainAddress",
      "TrustedDestination"
    ],
    "SettlementInstructions": [
      "SettlementInstructionsCrypto",
      "SettlementInstructionsFiat"
    ],
    "SignedOperationDetails": [
      "SignedOperationDetailsAddress",
      "SignedOperationDetailsAmount",
      "SignedOperationDetailsPreviewLayout"
    ],
    "StagedUser": [
      "APIStagedUser",
      "AppStagedUser"
    ],
    "StakingStep": [
      "AddressInputStep",
      "AmountInputStep",
      "AmountListStep",
      "AutoCompoundStep",
      "ValidatorInputStep"
    ],
    "TokenInfo": [
      "NftTokenInfo"
    ],
    "UnsignedOperationDetails": [
      "UnsignedOperationDetailsAddress",
      "UnsignedOperationDetailsAmount",
      "UnsignedOperationDetailsDivider",
      "UnsignedOperationDetailsText",
      "UnsignedOperationDetailsTransaction"
    ],
    "ValidatorDetail": [
      "CeloValidatorGroupDetail",
      "NonAddressValidatorDetail",
      "OasisValidatorDetail"
    ],
    "VoteDetails": [
      "CeloProposalDetails",
      "MakerPollDetails",
      "MakerVoteDetails"
    ],
    "WalletDepositInstructions": [
      "BlockchainAddress",
      "DepositInstructions"
    ],
    "WalletKeyExportPreference": [
      "WalletKeyExportDownload",
      "WalletKeyExportEmail",
      "WalletKeyExportUnknown"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Actor": [
      "APIKeyV2",
      "AnchorageActor",
      "UserKey",
      "UserV2"
    ],
    "Button": [
      "BooleanButton"
    ],
    "ErrorMessage": [
      "OutstandingHoldsError"
    ],
    "IAMMember": [
      "IAMMemberUser"
    ],
    "IAMResource": [
      "Organization",
      "Vault"
    ],
    "Initiator": [
      "APIKey",
      "User"
    ],
    "Key": [
      "APIKeyV2",
      "UserKey"
    ],
    "Node": [
      "APIKeyV2",
      "AccountConstraint",
      "AccountConstraintGroup",
      "AccountConstraintState",
      "AccountV2",
      "AddressBalance",
      "AffiliateAnchorageRelationship",
      "AffiliateV2",
      "AllocatedCollateral",
      "AnchorageLegalEntity",
      "AssetReferenceData",
      "AssetSpec",
      "BlockchainAddress",
      "BorrowerInterestSnapshot",
      "CCRValuationCollateralDetails",
      "CCRValuationExposureDetails",
      "CCRValuations",
      "CRRScoreDetails",
      "ClientOrder",
      "ClientPaymentFlow",
      "ClientSettlement",
      "CollateralAccount",
      "CollateralAgreement",
      "CollateralCall",
      "CollateralFee",
      "CollateralInventory",
      "CollateralRehypoLedger",
      "CollateralSettlement",
      "CryptoCollateralDetails",
      "DataSource",
      "DepositAttributionSpamThreshold",
      "EligibilityProof",
      "EligibilitySchedule",
      "EnabledModules",
      "EquityCollateralDetails",
      "Exposure",
      "ExternalAccount",
      "ExternalAccountLocation",
      "ExternalAccountTransaction",
      "FeeReimbursement",
      "IAMCategory",
      "IAMMemberUser",
      "IAMPermission",
      "IAMRole",
      "IAMUserAccess",
      "IAMUserRole",
      "IndividualAffiliateRelationshipAudit",
      "InterestPayments",
      "KYCProcess",
      "KYCProcessTrigger",
      "KYCQuestion",
      "KYCQuestionnaire",
      "KYCQuestionnaireSubmission",
      "KeyInfo",
      "LendingEntity",
      "LendingFacility",
      "LendingFacilityDailyApy",
      "LendingFacilityFee",
      "LendingFacilityFeeAmountSnapshot",
      "LendingFacilityMembership",
      "LendingFacilityMembershipStake",
      "LendingFacilityTransaction",
      "LendingInvoice",
      "LendingJobHistoryRecord",
      "LendingScheduledJobRecord",
      "Loan",
      "LoanAmountSnapshot",
      "MiningCollateralDetails",
      "MintBurnConversion",
      "OrganizationModule",
      "Party",
      "PaymentFlow",
      "Person",
      "PolicyFragmentBinding",
      "PolicyQuorum",
      "PriceReferenceData",
      "ReadPolicy",
      "ReadQuorum",
      "Report",
      "ReportDataFilter",
      "ReportDataFilterValue",
      "ReportFile",
      "ReportSchedule",
      "ReportScheduleDataFilter",
      "ReportScheduleDataFilterValue",
      "ReportScheduleFileFormats",
      "ReportScheduleMainFilter",
      "ReportScheduleSftpDestination",
      "SNParticipant",
      "SNTwoWaySettlement",
      "TransferRequest",
      "UniqueDevice",
      "User",
      "UserKey",
      "UserV2",
      "Vault",
      "Wallet",
      "WalletConnectSession",
      "WalletGroup",
      "WebhookEndpoint",
      "WebhookEvent",
      "WebhookEventType",
      "WebhookNotification",
      "WebhookSubscription"
    ],
    "Operation": [
      "AddOrganizationOperation",
      "AddTrustedDestinationOperation",
      "AddUserAndPoliciesOperation",
      "AuthorizeSpenderOperation",
      "BalanceAdjustmentOperation",
      "ChangeRoleOperation",
      "CreateVaultOperation",
      "DelegateOperation",
      "DepositEventOperation",
      "DepositOperation",
      "DisableVaultOperation",
      "GenericOperation",
      "GenericOrgChangeOperation",
      "LockOperation",
      "OffChainOperation",
      "OneOffOperation",
      "RemoveTrustedDestinationOperation",
      "RemoveUserAndPoliciesOperation",
      "ReplaceUserOperation",
      "TransferOperation",
      "UnlockOperation",
      "UpdateOrgPoliciesOperation",
      "UpdateVaultPoliciesOperation",
      "VoteOperation",
      "WithdrawOperation"
    ],
    "OperationField": [
      "SignedOperationField",
      "UnsignedOperationField"
    ],
    "PolicyFragment": [
      "PolicyStructuredFragment"
    ],
    "PolicyResource": [
      "Organization",
      "Vault"
    ],
    "PresentationRule": [
      "PresentationRuleJustOnce"
    ],
    "Resource": [
      "Organization",
      "Vault"
    ],
    "RolePermissionDestinationDetail": [
      "AMLBlockchainAddress",
      "TrustedDestination"
    ],
    "SettlementInstructions": [
      "SettlementInstructionsCrypto",
      "SettlementInstructionsFiat"
    ],
    "SignedOperationDetails": [
      "SignedOperationDetailsAddress",
      "SignedOperationDetailsAmount",
      "SignedOperationDetailsPreviewLayout"
    ],
    "StagedUser": [
      "APIStagedUser",
      "AppStagedUser"
    ],
    "StakingStep": [
      "AddressInputStep",
      "AmountInputStep",
      "AmountListStep",
      "AutoCompoundStep",
      "ValidatorInputStep"
    ],
    "TokenInfo": [
      "NftTokenInfo"
    ],
    "UnsignedOperationDetails": [
      "UnsignedOperationDetailsAddress",
      "UnsignedOperationDetailsAmount",
      "UnsignedOperationDetailsDivider",
      "UnsignedOperationDetailsText",
      "UnsignedOperationDetailsTransaction"
    ],
    "ValidatorDetail": [
      "CeloValidatorGroupDetail",
      "NonAddressValidatorDetail",
      "OasisValidatorDetail"
    ],
    "VoteDetails": [
      "CeloProposalDetails",
      "MakerPollDetails",
      "MakerVoteDetails"
    ],
    "WalletDepositInstructions": [
      "BlockchainAddress",
      "DepositInstructions"
    ],
    "WalletKeyExportPreference": [
      "WalletKeyExportDownload",
      "WalletKeyExportEmail",
      "WalletKeyExportUnknown"
    ]
  }
};
      export default result;
    