import cn from 'classnames';

export const MoreIcon = ({
  className,
  ...restProps
}: SVGProps): JSX.Element => {
  return (
    <svg
      className={cn('fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      {...restProps}
    >
      <path d="M6.1875 11C6.1875 10.2406 5.57189 9.625 4.8125 9.625C4.05311 9.625 3.4375 10.2406 3.4375 11C3.4375 11.7594 4.05311 12.375 4.8125 12.375C5.57189 12.375 6.1875 11.7594 6.1875 11Z" />
      <path d="M11 9.625C11.7594 9.625 12.375 10.2406 12.375 11C12.375 11.7594 11.7594 12.375 11 12.375C10.2406 12.375 9.625 11.7594 9.625 11C9.625 10.2406 10.2406 9.625 11 9.625Z" />
      <path d="M17.1875 9.625C17.9469 9.625 18.5625 10.2406 18.5625 11C18.5625 11.7594 17.9469 12.375 17.1875 12.375C16.4281 12.375 15.8125 11.7594 15.8125 11C15.8125 10.2406 16.4281 9.625 17.1875 9.625Z" />
    </svg>
  );
};
