const A1ChartIllustration = (props: SVGProps): JSX.Element => {
  const { title = 'A1ChartIllustration', ...restProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 190 108"
      {...restProps}
      fill="none"
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_52_3785)">
        <g>
          <path
            fill="inherit"
            d="M184.838 36.71a2.772 2.772 0 100-5.544 2.772 2.772 0 000 5.543z"
          ></path>
          <path
            fill="inherit"
            d="M184.838 66.09a2.772 2.772 0 100-5.543 2.772 2.772 0 000 5.544z"
          ></path>
          <path
            fill="inherit"
            d="M184.838 104.632a2.771 2.771 0 10.002-5.542 2.771 2.771 0 00-.002 5.542z"
          ></path>
          <path
            fill="#fff"
            d="M187.454 94.398l-2.627-2.627-2.627 2.627 2.627 2.627 2.627-2.627z"
          ></path>
        </g>
        <path
          stroke="#fff"
          fill="none"
          strokeMiterlimit="10"
          strokeWidth="0.724"
          d="M54.26 74.71a2.883 2.883 0 10-5.767 0v.058a2.883 2.883 0 105.767 0v-.058z"
        ></path>
        <path
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="0.724"
          fill="none"
          d="M58.612 71.827h-7.206a2.912 2.912 0 100 5.825h7.206a2.913 2.913 0 000-5.825z"
        ></path>
        <g
          stroke="inherit"
          fill="none"
          strokeMiterlimit="10"
          strokeWidth="0.724"
        >
          <path d="M119.582.362H67.769v50.387h51.813V.362z"></path>
          <path d="M68.005 12.63h51.266"></path>
          <path d="M68.005 25.257h51.266"></path>
          <path d="M68.005 37.883h51.266"></path>
          <g>
            <path d="M80.682.716V50.75"></path>
            <path d="M93.048.716V50.75"></path>
            <path d="M106.713.716V50.75"></path>
          </g>
        </g>
        <path fill="inherit" d="M42.296 72.084H6v5.159h36.296v-5.16z"></path>
        <path
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="0.724"
          fill="none"
          d="M62.853 82.402H6v5.16h56.853v-5.16z"
        ></path>
        <path fill="inherit" d="M55.78 92.658H6.082v5.054H55.78v-5.054z"></path>
        <path
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="0.724"
          fill="none"
          d="M8.48 66.545H1.028v37.063h7.581"
        ></path>
        <path
          fill="#fff"
          stroke="none"
          d="M62.518 98.554s4.207-6.727 6.866-6.865c2.659-.137 4.283 2.01 4.945 2.01.662 0 1.92 1.316 4.799-1.388 2.879-2.704 9.67-14.214 9.67-14.214s2.953-4.508 6.497-2.426c0 0 1.254 1.041 1.92 2.426.666 1.385 2.213 3.33 5.168 3.189 2.955-.14 4.945-5.061 4.945-5.061s2.14-6.312 5.831-7.075c3.691-.762 5.095 4.299 5.095 4.299l9.967 25.033-65.703.069v.003z"
        ></path>
        <path
          stroke="inherit"
          strokeMiterlimit="10"
          strokeWidth="0.724"
          d="M133.808 103.608h40.419"
        ></path>
        <path
          fill="#fff"
          stroke="none"
          d="M133.275 98.554h10.108l-5.432-48.856-4.676 48.856z"
        ></path>
        <path
          fill="#fff"
          stroke="none"
          d="M164.442 98.554h10.108l-5.428-39.59-4.68 39.59z"
        ></path>
        <path
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="0.724"
          fill="none"
          d="M149.28 98.554h10.108l-5.429-73.284-4.679 73.284z"
        ></path>
        <path
          stroke="inherit"
          fill="none"
          strokeMiterlimit="10"
          strokeWidth="0.724"
          d="M138.67 46.917a6.758 6.758 0 006.758-6.758 6.758 6.758 0 10-13.516 0 6.758 6.758 0 006.758 6.758z"
        ></path>
        <g stroke="inherit" strokeMiterlimit="10" strokeWidth="0.724">
          <path d="M105.895 61.81l2.185 2.096 4.628-4.715"></path>
          <path d="M108.847 68.23v11.793"></path>
          <path d="M109.039 82.295a1.136 1.136 0 100-2.272 1.136 1.136 0 000 2.272z"></path>
          <path
            fill="none"
            d="M108.844 68.269a6.758 6.758 0 100-13.517 6.758 6.758 0 000 13.517z"
          ></path>
        </g>
        <g
          stroke="inherit"
          fill="none"
          strokeMiterlimit="10"
          strokeWidth="0.724"
        >
          <path d="M87.789 73.352v17.69"></path>
          <path d="M87.69 93.313a1.136 1.136 0 100-2.272 1.136 1.136 0 000 2.272z"></path>
          <path d="M84.343 66.865l2.185 2.095 4.628-4.718"></path>
          <path d="M87.292 73.323a6.758 6.758 0 100-13.517 6.758 6.758 0 000 13.517z"></path>
        </g>
        <path
          stroke="inherit"
          strokeMiterlimit="10"
          strokeWidth="0.724"
          d="M148.942 50.412l-5.547-5.492"
        ></path>
        <path
          stroke="inherit"
          strokeMiterlimit="10"
          strokeWidth="0.724"
          d="M138.706 36.787v6.672"
        ></path>
        <path
          stroke="inherit"
          strokeMiterlimit="10"
          strokeWidth="0.724"
          d="M135.476 40.123h6.461"
        ></path>
        <path
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="0.724"
          fill="none"
          d="M67.77 51.386L80.71 26.31l12.103 12.467 13.824-25.43 12.945 38.038"
        ></path>
        <path
          fill="#fff"
          stroke="none"
          d="M74.282 40.315a1.79 1.79 0 100-3.582 1.79 1.79 0 000 3.582z"
        ></path>
        <path
          fill="#fff"
          stroke="none"
          d="M80.845 27.934a1.79 1.79 0 100-3.582 1.79 1.79 0 000 3.582z"
        ></path>
        <path
          fill="#fff"
          stroke="none"
          d="M92.856 40.467a1.79 1.79 0 100-3.582 1.79 1.79 0 000 3.582z"
        ></path>
        <path
          fill="#fff"
          stroke="none"
          d="M100.081 27.648a1.791 1.791 0 100-3.582 1.791 1.791 0 000 3.582z"
        ></path>
        <path
          fill="#fff"
          stroke="none"
          d="M106.575 15.416a1.791 1.791 0 100-3.582 1.791 1.791 0 000 3.582z"
        ></path>
        <path
          stroke="none"
          fill="#fff"
          d="M111.051 27.616a1.791 1.791 0 100-3.582 1.791 1.791 0 000 3.582z"
        ></path>
        <path
          stroke="none"
          fill="#fff"
          d="M115.23 40.145a1.79 1.79 0 100-3.58 1.79 1.79 0 000 3.58z"
        ></path>
        <g stroke="none">
          <path
            fill="inherit"
            d="M136.441 4.642h-2.966v5.264h2.966V4.642z"
          ></path>
          <path fill="#fff" d="M136.441 9.873h-2.966V22.7h2.966V9.873z"></path>
          <path fill="inherit" d="M143.377 6.78h-2.967v3.13h2.967V6.78z"></path>
          <path
            fill="#fff"
            d="M143.377 9.873h-2.967v12.048h2.967V9.873z"
          ></path>
          <path
            fill="inherit"
            d="M150.316 8.137h-2.967v1.772h2.967V8.137z"
          ></path>
          <path fill="#fff" d="M150.316 9.873h-2.967v8.973h2.967V9.873z"></path>
          <path fill="inherit" d="M157.252 6.82h-2.967v3.09h2.967V6.82z"></path>
          <path fill="#fff" d="M157.252 9.873h-2.967v2.967h2.967V9.873z"></path>
          <path
            fill="inherit"
            d="M164.191 3.947h-2.967V9.91h2.967V3.947z"
          ></path>
          <path fill="#fff" d="M164.191 9.873h-2.967v8.633h2.967V9.873z"></path>
          <path fill="inherit" d="M171.126.362h-2.966V9.91h2.966V.362z"></path>
          <path
            fill="#fff"
            d="M171.126 9.873h-2.966v34.143h2.966V9.873z"
          ></path>
        </g>
        <path
          fill="#fff"
          stroke="none"
          d="M26.677 36.932c10.099 0 18.285-8.187 18.285-18.285C44.962 8.548 36.776.362 26.677.362 16.58.362 8.392 8.548 8.392 18.647c0 10.098 8.187 18.285 18.285 18.285z"
        ></path>
        <path
          fill="#222325"
          stroke="none"
          d="M37.903 18.643l-11.226-7.185-11.223 7.185 11.223 7.189 11.226-7.189z"
        ></path>
        <path
          stroke="inherit"
          strokeMiterlimit="10"
          strokeWidth="0.724"
          d="M58.384 54.233H53.24l2.521 2.724 2.623-2.724z"
        ></path>
        <path
          stroke="inherit"
          strokeMiterlimit="10"
          strokeWidth="0.724"
          fill="none"
          d="M32.886 31.19s21.653-.817 22.94 23.177"
        ></path>
        <g stroke="#fff" strokeMiterlimit="10" strokeWidth="1.085">
          <path d="M.968 48.72h39.464"></path>
          <path d="M.968 43.017h24.613"></path>
          <path d="M.968 54.417h29.28"></path>
          <path d="M.968 60.539h39.888"></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_52_3785">
          <path
            fill="#fff"
            d="M0 0H188.787V108H0z"
            transform="translate(.606)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export { A1ChartIllustration };
