import cn from 'classnames';

interface Spinner extends Partial<Document> {
  strokeClassName?: string;
  className?: string;
}
// To be removed after the spinner component is migrated to the new design system
const Spinner = ({
  title = 'Spinner',
  strokeClassName,
  className,
  ...restProps
}: Spinner): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('fill-current', className)}
      {...restProps}
    >
      <title>{title}</title>
      <path
        d="M5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12Z"
        stroke="#161718"
        strokeOpacity="0.08"
        strokeWidth="2"
        className={strokeClassName}
      />
      <mask
        id="mask0_448_19027"
        maskUnits="userSpaceOnUse"
        x="12"
        y="4"
        width="8"
        height="8"
      >
        <rect x="12" y="4" width="8" height="8" fill="#76787F" />
      </mask>
      <g mask="url(#mask0_448_19027)">
        <circle
          cx="12"
          cy="12"
          r="7"
          stroke="#161718"
          strokeWidth="2"
          className={strokeClassName}
        />
      </g>
    </svg>
  );
};

export { Spinner };
