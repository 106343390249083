import cn from 'classnames';

const HumanUserIcon = (props: SVGProps): JSX.Element => {
  const { title = 'HumanUser', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <g id=" / Human User">
        <g id="Icon">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.49997 2.76392C7.96955 2.2893 7.26852 2 6.5 2C4.84315 2 3.5 3.34315 3.5 5C3.5 6.65685 4.84315 8 6.5 8C7.26852 8 7.96955 7.7107 8.49997 7.23608C9.03073 7.71113 9.73163 8 10.5 8C12.1569 8 13.5 6.65685 13.5 5C13.5 3.34315 12.1569 2 10.5 2C9.73163 2 9.03073 2.28887 8.49997 2.76392ZM6.5 3C5.39543 3 4.5 3.89543 4.5 5C4.5 6.10457 5.39543 7 6.5 7C7.0322 7 7.51584 6.79248 7.87461 6.4528C7.64896 6.04588 7.51516 5.58098 7.50121 5.0861C7.50041 5.05749 7.5 5.02879 7.5 5C7.5 4.96923 7.50046 4.93857 7.50139 4.90802C7.51622 4.41533 7.64985 3.9525 7.87461 3.5472C7.51584 3.20752 7.0322 3 6.5 3ZM8.50068 4.94733C8.50023 4.96483 8.5 4.98239 8.5 5C8.5 5.02105 8.50032 5.04202 8.50097 5.06291C8.5342 6.13837 9.41648 7 10.5 7C11.6046 7 12.5 6.10457 12.5 5C12.5 3.89543 11.6046 3 10.5 3C9.41304 3 8.5286 3.8671 8.50068 4.94733Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.5 9.5C3.11929 9.5 2 10.6193 2 12V13C2 13.5523 2.44772 14 3 14H14C14.5523 14 15 13.5523 15 13V12C15 10.6193 13.8807 9.5 12.5 9.5H4.5ZM6.49982 10.5H4.5C3.67157 10.5 3 11.1716 3 12V13H6V12C6 11.4372 6.18597 10.9178 6.49982 10.5ZM7 13H14V12C14 11.1716 13.3284 10.5 12.5 10.5H8.5C7.67157 10.5 7 11.1716 7 12V13Z"
          />
        </g>
      </g>
    </svg>
  );
};

export { HumanUserIcon };
