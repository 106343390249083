import cn from 'classnames';

export const ProductWarning = ({
  className,
  ...restProps
}: SVGProps): JSX.Element => {
  return (
    <svg
      className={cn('fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      {...restProps}
    >
      <path d="M2 42H46L24 4L2 42ZM26 36H22V32H26V36ZM26 28H22V20H26V28Z" />
    </svg>
  );
};
